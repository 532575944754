import React from 'react';
import { Heading } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';


const SectionCancellationPolicyMaybe = ({
  cancellationPolicy,
  heading,
  showAsIngress = false,
}) => {
  const {
    policy,
    percentage,
  } = cancellationPolicy;

  const textClass = showAsIngress ? css.ingress : css.text;
  return (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <div className={textClass}>
        <div className={css.cancellationPolicyLabel}>
          <FormattedMessage id="ListingPage.listingCancellationPolicyLabel" />
          <FormattedMessage
            id="ListingPage.listingCancellationPolicy"
            values={{ policy: policy < 2 ? (policy * 24) + " hours" : policy + " days" }}
          />
        </div>
        <FormattedMessage
          id="ListingPage.listingCancellationPolicyPercentage"
          values={{ percentage: <span className={css.percentage}>{percentage}%</span> }}
        />
      </div>
    </div>
  );
};

export default SectionCancellationPolicyMaybe;
