import React from 'react';
import { Heading, DarftContent } from '../../components';

import css from './ListingPage.module.css';

const SectionRentalRulesMaybe = ({
  text,
  heading,
  showAsIngress = false,
}) => {

  const textClass = showAsIngress ? css.ingress : css.text;
  return text ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <DarftContent draftContent={text} className={textClass} />
      {/* <p className={textClass}>
        {text}
      </p> */}
    </div>
  ) : null;
};

export default SectionRentalRulesMaybe;
