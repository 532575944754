import React, { useState, useEffect } from 'react';
import ReactImageGallery from 'react-image-gallery';
import { types } from 'sharetribe-flex-sdk';
const { Money } = types;
import classNames from 'classnames';
import { createSlug } from '../../../util/urlHelpers';

import {
  IconArrowHead,
  ResponsiveImage,
  AspectRatioWrapper,
  H4,
  NamedLink,
  AddFavorites
} from '../../../components';

// import {
//   FishingGear,
//   PaddleBoards,
//   Utvs,
//   MountainBikes,
//   WaterSports,
//   CampingGear,
//   Scooters,
//   TourPackage,
//   RockClimbingGear,
//   RvTrailer,
//   HangGliding,
//   Paragliding,
//   Atvs,
//   WinterSports,
//   GolfGear,
//   Ebikes,
//   TowingHaulingEquipment,
//   Motorcycles,
// } from '../../../components/ListingTypesIcons/ListingTypesIcons';

import { formatMoney } from '../../../util/currency';

import { getListingCityName } from './ProviderListings.helper';

import css from './ProvidersListings.module.css';

const PinIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.63485 0.272827C4.29344 0.27276 3.0062 0.802128 2.05292 1.74588C1.09964 2.68962 0.557359 3.97148 0.543945 5.31283C0.543945 8.8001 5.03031 12.6819 5.22122 12.8474C5.33648 12.946 5.48318 13.0001 5.63485 13.0001C5.78653 13.0001 5.93322 12.946 6.04849 12.8474C6.27122 12.6819 10.7258 8.8001 10.7258 5.31283C10.7123 3.97148 10.1701 2.68962 9.21679 1.74588C8.26351 0.802128 6.97627 0.27276 5.63485 0.272827ZM5.63485 11.5046C4.57213 10.4928 1.81667 7.68646 1.81667 5.31283C1.81667 4.30018 2.21894 3.32901 2.93499 2.61296C3.65104 1.89692 4.62221 1.49465 5.63485 1.49465C6.6475 1.49465 7.61867 1.89692 8.33472 2.61296C9.05077 3.32901 9.45304 4.30018 9.45304 5.31283C9.45304 7.66737 6.69758 10.4928 5.63485 11.5046Z" fill="#33A4D6" />
      <path d="M5.6345 2.81824C5.19399 2.81824 4.76337 2.94886 4.39709 3.1936C4.03082 3.43834 3.74535 3.78619 3.57677 4.19317C3.40819 4.60015 3.36408 5.04798 3.45002 5.48003C3.53596 5.91208 3.74809 6.30894 4.05958 6.62043C4.37107 6.93192 4.76793 7.14405 5.19998 7.22999C5.63203 7.31593 6.07986 7.27182 6.48684 7.10324C6.89382 6.93466 7.24167 6.64919 7.48641 6.28292C7.73115 5.91664 7.86177 5.48602 7.86177 5.04551C7.86177 4.4548 7.62711 3.88829 7.20942 3.47059C6.79173 3.0529 6.22521 2.81824 5.6345 2.81824ZM5.6345 6.00006C5.44571 6.00006 5.26116 5.94407 5.10418 5.83919C4.94721 5.7343 4.82486 5.58522 4.75262 5.4108C4.68037 5.23638 4.66146 5.04445 4.6983 4.85929C4.73513 4.67412 4.82604 4.50404 4.95953 4.37054C5.09303 4.23705 5.26311 4.14614 5.44828 4.10931C5.63344 4.07247 5.82537 4.09138 5.99979 4.16362C6.17421 4.23587 6.32329 4.35822 6.42818 4.51519C6.53306 4.67217 6.58905 4.85672 6.58905 5.04551C6.58905 5.29867 6.48848 5.54146 6.30947 5.72048C6.13045 5.89949 5.88766 6.00006 5.6345 6.00006Z" fill="#33A4D6" />
    </svg>
  )
}

const StarIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9676 5.08744C11.8891 4.87255 11.684 4.72747 11.4539 4.72747H7.50286L6.52547 1.30552C6.45784 1.07099 6.24404 0.909546 5.99969 0.909546C5.75644 0.909546 5.54154 1.07099 5.475 1.30552L4.49652 4.72747H0.545519C0.315353 4.72747 0.111367 4.87255 0.0328268 5.08744C-0.0457133 5.30343 0.0197368 5.5445 0.196452 5.69286L3.17007 8.17123L1.67017 12.1724C1.58618 12.3971 1.65926 12.6502 1.85016 12.7964C2.03887 12.9425 2.30394 12.9469 2.49811 12.8073L5.99969 10.3071L9.50127 12.8073C9.69653 12.9469 9.96051 12.9425 10.1503 12.7964C10.3412 12.6502 10.4132 12.3971 10.3292 12.1724L8.82932 8.17123L11.804 5.69286C11.9807 5.5445 12.0451 5.30343 11.9676 5.08744Z" fill="#E4696A" />
    </svg>
  )
}

// const LikeIcon = ({ selected }) => {
//   return (
//     <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <path opacity="0" d="M16.5 3.61975C14.76 3.61975 13.09 4.42975 12 5.70975C10.91 4.42975 9.24 3.61975 7.5 3.61975C4.42 3.61975 2 6.03975 2 9.11975C2 12.8998 5.4 15.9798 10.55 20.6598L12 21.9698L13.45 20.6498C18.6 15.9798 22 12.8998 22 9.11975C22 6.03975 19.58 3.61975 16.5 3.61975Z" fill="  " />
//       <path d="M16.5 3.61975C14.76 3.61975 13.09 4.42975 12 5.70975C10.91 4.42975 9.24 3.61975 7.5 3.61975C4.42 3.61975 2 6.03975 2 9.11975C2 12.8998 5.4 15.9798 10.55 20.6598L12 21.9698L13.45 20.6498C18.6 15.9798 22 12.8998 22 9.11975C22 6.03975 19.58 3.61975 16.5 3.61975ZM12.1 19.1698L12 19.2698L11.9 19.1698C7.14 14.8598 4 12.0098 4 9.11975C4 7.11975 5.5 5.61975 7.5 5.61975C9.04 5.61975 10.54 6.60975 11.07 7.97975H12.94C13.46 6.60975 14.96 5.61975 16.5 5.61975C18.5 5.61975 20 7.11975 20 9.11975C20 12.0098 16.86 14.8598 12.1 19.1698Z" fill={selected ? "crimson" : "white"} />
//     </svg>
//   )
// }

// const listingTypesIcons = {
//   fishingGear: FishingGear,
//   paddleBoards: PaddleBoards,
//   utvs: Utvs,
//   mountainBikes: MountainBikes,
//   waterSports: WaterSports,
//   campingGear: CampingGear,
//   scooters: Scooters,
//   tourPackage: TourPackage,
//   rockClimbingGear: RockClimbingGear,
//   rvTrailer: RvTrailer,
//   hangGliding: HangGliding,
//   paragliding: Paragliding,
//   atvs: Atvs,
//   winterSports: WinterSports,
//   golfGear: GolfGear,
//   ebikes: Ebikes,
//   towingHaulingEquipment: TowingHaulingEquipment,
//   motorcycles: Motorcycles,
// }

// const convertDegToRad = value => value * Math.PI / 180;

// const getDistanceFromLatLonInKm = (point1, point2) => {

//   const [lat1, lon1] = point1;
//   const [lat2, lon2] = point2;
//   const earthRadius = 6371;
//   const dLat = convertDegToRad(lat2 - lat1);
//   const dLon = convertDegToRad(lon2 - lon1);
//   const squarehalfChordLength =
//     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//     Math.cos(convertDegToRad(lat1)) * Math.cos(convertDegToRad(lat2)) *
//     Math.sin(dLon / 2) * Math.sin(dLon / 2);

//   const angularDistance = 2 * Math.atan2(Math.sqrt(squarehalfChordLength), Math.sqrt(1 - squarehalfChordLength));
//   const distance = earthRadius * angularDistance;
//   return distance.toFixed(1);

// }

const MAX_LANDSCAPE_ASPECT_RATIO = 2; // 2:1
const MAX_PORTRAIT_ASPECT_RATIO = 4 / 3;

const getFirstImageAspectRatio = (firstImage, scaledVariant) => {
  if (!firstImage) {
    return { aspectWidth: 1, aspectHeight: 1 };
  }

  const v = firstImage?.attributes?.variants?.[scaledVariant];
  const w = v?.width;
  const h = v?.height;
  const hasDimensions = !!w && !!h;
  const aspectRatio = w / h;

  return hasDimensions && aspectRatio >= MAX_LANDSCAPE_ASPECT_RATIO
    ? { aspectWidth: 2, aspectHeight: 1 }
    : hasDimensions && aspectRatio <= MAX_PORTRAIT_ASPECT_RATIO
      ? { aspectWidth: 4, aspectHeight: 3 }
      : hasDimensions
        ? { aspectWidth: w, aspectHeight: h }
        : { aspectWidth: 1, aspectHeight: 1 };
};

const ProviderListings = ({
  // currentListing,
  listing,
  config,
  intl,
  reviews,
  // onAddToFavorites,
  currentUser,
  onManageDisableScrolling
}) => {
  const [listingCity, setListingCity] = useState(null);

  const variantPrefix = config.layout.listingImage.variantPrefix;
  const images = listing.images;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  const thumbVariants = thumbnailVariants || imageVariants;

  // const favorites = currentUser?.attributes?.profile?.publicData?.favorites ?? [];

  // const currentGeolocation = currentListing?.attributes?.geolocation;
  const { title, publicData, geolocation } = listing?.attributes;
  const {
    prices = [],
    // itemType
  } = publicData;
  // const ItemTypeIcon = listingTypesIcons[itemType];
  const listingId = listing?.id?.uuid;
  const listingSlug = createSlug(title);

  // const distanceBetweenListings = getDistanceFromLatLonInKm(
  //   [geolocation.lat, geolocation.lng],
  //   [currentGeolocation.lat, currentGeolocation.lng]
  // );

  // const isFavorite = favorites.find(
  //   ({ favoritesListings }) => favoritesListings.includes(listingId)
  // );

  // const displayName = listing.author.attributes.profile.displayName;

  const reviewRate = reviews.length ? (Math.round(
    (reviews.reduce((total, item) => total + item.attributes.rating, 0) / reviews.length) * 10
  ) / 10).toFixed(1) : 0;

  const pricesCopy = [...prices];
  pricesCopy.sort((a, b) => {
    const [aKey] = Object.keys(a);
    const [bKey] = Object.keys(b);
    const [aHours] = aKey.split("-");
    const [bHours] = bKey.split("-");

    return aHours - bHours;
  });

  const { aspectWidth, aspectHeight } = getFirstImageAspectRatio(images?.[0], imageVariants[0]);
  const imageSizesMaybe = { sizes: `(max-width: 1024px) 100vw, (max-width: 1200px) calc(100vw - 192px), 708px` };
  const items = images.map((img, i) => {
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbnail: img.attributes?.variants?.[thumbVariants[0]],
      image: img,
    };
  });

  const renderLeftNav = (onClick, disabled) => {
    return (
      <button className={css.navLeft} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="left" size="big" />
        </div>
      </button>
    );
  };
  const renderRightNav = (onClick, disabled) => {
    return (
      <button className={css.navRight} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="right" size="big" />
        </div>
      </button>
    );
  };

  const renderItem = item => {
    return (
      <NamedLink className={css.rooLink} name="ListingPage" params={{ id: listingId, slug: listingSlug }}>
        <AspectRatioWrapper
          width={aspectWidth || 1}
          height={aspectHeight || 1}
          className={css.itemWrapper}
        >
          <div className={css.itemCentering}>
            <ResponsiveImage
              rootClassName={css.item}
              image={item.image}
              alt={item.alt}
              variants={imageVariants}
              {...imageSizesMaybe}
            />
          </div>
        </AspectRatioWrapper>
      </NamedLink>
    );
  };

  // const handleRemoveFromFavorites = (e) => {
  //   e.stopPropagation();

  //   const values = favorites.filter(
  //     ({ favoritesListings }) => !favoritesListings.includes(listingId)
  //   );

  //   onAddToFavorites(values);
  // };

  // const handleAddToFavorites = (e) => {
  //   e.stopPropagation();

  //   const listingImages = [
  //     images?.[0]?.attributes?.variants?.['listing-card']?.url,
  //     images?.[0]?.attributes?.variants?.['listing-card-2x']?.url
  //   ];

  //   const values = {
  //     favoritesId: `${createSlug(title)}-${Math.random().toFixed(4)}-${Math.random().toFixed(4)}`,
  //     favoritesTitle: title,
  //     favoritesImage: listingImages,
  //     favoritesListings: !!listingId ? [listingId] : null
  //   };

  //   onAddToFavorites([...favorites, values]);
  // }

  useEffect(() => {
    getListingCityName(geolocation.lat, geolocation.lng)
      .then(res => setListingCity(res));
  }, [geolocation.lat, geolocation.lng]);

  if (items.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  return (
    <div className={css.root}>
      <div
        className={css.favoritesContainer}
      // onClick={isFavorite ? handleRemoveFromFavorites : handleAddToFavorites}
      >
        {!!currentUser && (
          <AddFavorites
            listing={listing}
            className={css.addToFavorites}
            onManageDisableScrolling={onManageDisableScrolling}
            isOverlayPosition
          />
        )}
      </div>
      <ReactImageGallery
        additionalClass={classNames(css.productGallery, { [css.productGallerySingle]: items?.length === 1 })}
        items={items}
        renderItem={renderItem}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        showPlayButton={false}
        disableThumbnailScroll={true}
        showThumbnails={false}
        showFullscreenButton={false}
        showBullets={true}
      />
      <NamedLink className={css.rooLink} name="ListingPage" params={{ id: listingId, slug: listingSlug }}>
        <H4 as="h2" className={css.orderPanelTitle}>
          {title}
        </H4>
      </NamedLink>

      <div className={css.listingLocation}>
        <PinIcon />
        <span className={css.location}>{listingCity}</span>
        {/* <span>&#x2022; </span>
        <span className={css.location}>
          {intl.formatMessage({ id: "ProviderListings.distanceBetweenListings" }, { distanceBetweenListings })}
        </span> */}
      </div>

      <ul className={css.pricesContainer}>
        {prices.map(pricesCopy => {
          const key = Object.keys(pricesCopy)[0];
          const priceLabel = key === 'Daily' ? key : key.split("-")[0] + 'hr';
          const { amount, currency } = pricesCopy[key];

          const formattedPrice = formatMoney(intl, new Money(amount, currency));

          return (
            <li key={key} className={classNames(css.priceItem, { [css.priceItemDaily]: key === 'Daily' })}>
              <span className={css.priceTime}>
                {intl.formatMessage({ id: "ProviderListings.priceLabel" }, { priceLabel })}
              </span>
              <span className={css.priceAmount}>
                {amount / 100 + formattedPrice[0]}
              </span>
            </li>
          )
        })}
      </ul>

      <hr className={css.totalDivider} />

      <div className={css.reviewsContainer}>
        {/* <div>
          <span className={css.reviewUser}>
            {intl.formatMessage({ id: "ProviderListings.reviewUser" })}
          </span>
          {" "}
          <NamedLink className={css.link} name="ProfilePage" params={{ id: listing.author.id.uuid }}>
            {displayName}
          </NamedLink>
        </div> */}
        <div>
          <StarIcon />
          <span className={css.reviewRate}>{reviewRate}</span>
          <span className={css.reviewCount}>
            {intl.formatMessage({ id: "ProviderListings.reviewRate" }, { count: reviews.length })}
          </span>
        </div>
      </div>

      {/* <div className={css.additionalRentalsContainer}>
        <span className={css.additionalRentals}>
          {intl.formatMessage({ id: "ProviderListings.additionalRentals" })}
        </span>
        <span className={css.additionalRentalItem}>
          {ItemTypeIcon && <ItemTypeIcon />}
        </span>
      </div> */}
    </div>
  )
}

export default ProviderListings;
