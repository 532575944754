import React, { useState } from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';
import { formatMoney } from '../../util/currency';
import { types } from 'sharetribe-flex-sdk';
import css from './ListingPage.module.css';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
const { Money } = types;
const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionPricingMaybe = props => {
  const { publicData, intl } = props;

  const [showFullPrice, setShowFullPrice] = useState(false)

  const { prices = [] } = publicData;

  const pricesCopy = [...prices];
  pricesCopy.sort((a, b) => {
    const [aKey] = Object.keys(a);
    const [bKey] = Object.keys(b);
    const [aHours] = aKey.split("-");
    const [bHours] = bKey.split("-");

    return aHours - bHours;
  });

  const additionalServices = !!publicData?.additionalServices?.length && publicData.additionalServices.map(item => item.price.amount) || []
  const additionalServicesTotal = additionalServices.reduce((partialSum, a) => partialSum + a, 0);

  const securityDeposit = publicData?.securityDeposit?.[0]?.price;

  return (
    <>
      <div className={classNames(css.sectionText, css.sectionTextIndent)}>
        <div className={css.pricesHeading}>
          <h2 className={css.sectionHeading}>
            <FormattedMessage id="ListingPage.listingPricingTitle" />
          </h2>

          <div className={css.toggleWrapper}>
            <span className={classNames(css.toggleItem, { [css.toggleItemActive]: showFullPrice })} onClick={() => setShowFullPrice(!showFullPrice)}>
              <FormattedMessage id="ListingPage.listingFullRates" />
            </span>
            <div
              className={classNames(css.toggle, { [css.toggleActive]: showFullPrice })}
              onClick={() => setShowFullPrice(!showFullPrice)}
            >
            </div>
          </div>
        </div>
        <ul className={css.pricesContainer}>
          {prices.map(pricesCopy => {
            const key = Object.keys(pricesCopy)[0];
            const priceLabel = key === 'Daily' ? key : key.split("-")[0] + 'hr';
            const { amount, currency } = pricesCopy[key];

            const formattedPrice = formatMoney(intl, new Money(amount, currency));

            return (
              <li key={key} className={classNames(css.priceItem, { [css.priceItemDaily]: key === 'Daily' })}>
                <span className={css.priceTime}>
                  {intl.formatMessage({ id: "ProviderListings.priceLabel" }, { priceLabel })}
                </span>
                <span className={css.priceAmount}>
                  {showFullPrice ?
                    formattedPrice[0] + (amount + additionalServicesTotal) / 100 :
                    formattedPrice[0] + amount / 100}
                </span>
              </li>
            )
          })}
        </ul>
      </div>

      {!!securityDeposit && (
        <div className={classNames(css.sectionText, css.sectionTextIndent)}>
          <h2 className={css.sectionHeading}>
            <FormattedMessage id="ListingPage.listingDepositTitle" />
          </h2>
          <div className={css.depositItem}>
            {formatMoney(intl, new Money(securityDeposit?.amount, securityDeposit?.currency))}
          </div>
        </div>
      )}
    </>
  )
};

export default SectionPricingMaybe;
